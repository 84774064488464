<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('account.role-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="role_items"
        :footer-props="footerProps"
      >
        <template v-slot:item.value="{ item }">{{
          item.value | getText(role_items) | Translate
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { role_items, action_items } from "@/definition.js";
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("account.role"),
        value: "value",
        width: "80%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    role_items: role_items,
  }),
  mixins: [BaseTable],
  computed: {},
  methods: {
    editItem(item) {
      this.$router.push({
        name: "RolePermissionList",
        query: {
          role: item.value
        }
      });
    }
  },
  mounted() {},
};
</script>